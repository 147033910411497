<template>

  <div class="pb">

    <B2BHead v-if="data_b2b && data_b2b.bank_info"
      :data="data_b2b.bank_info"
      />

    <UiTabs 
      v-if="tab_list"
      :tabs="tab_list" 
      :acttab="active_tab"
      />     

      <br/>

    <NuxtPage v-if="data_b2b"
      :data="data_b2b"
      />

  </div>

</template>

<script setup> 

import B2BHead from '@/pages/brands-manager/view/Head'  


const $route = useRouter();
const { $api } = useNuxtApp()
const { t } = useI18n();
// const tab_list = ref([])
const second_url = ref($route.currentRoute.value.path.split('/')[2]);
// const second_url = ref('information');
const active_tab = ref(second_url.value ? second_url.value : 'information')

 
 

const tab_list = computed((res) => {
  if(!data_b2b.value?.tabs_avaliable) 
    return;
   

  if(!data_b2b.value?.tabs_avaliable.find(tab => tab.id == 'welcome'))
  data_b2b.value?.tabs_avaliable.push({
    id: 'welcome',
    name: 'Welcome',
    enable: 0,
    link: '/brands-manager/welcome',
  });

  return data_b2b.value?.tabs_avaliable.map((tab) => {
    tab.enable = 0;
    if(data_b2b.value.is_subscribe == 1 && tab.id != 'banners') tab.enable = 1;
    if((data_b2b.value.is_subscribe == 1 || data_b2b.value.is_other_b2b == 1) && tab.id == 'banners') tab.enable = 1;
    tab.link = tab.id == 'information' ? '/brands-manager' : '/brands-manager/' + tab.id;
    tab.name = t(tab.name);
    return tab;
  });
});


const loadData = async function() { 
  const response = await $api.getB2BData();
  return response; 
}

const { pending: is_loading,  data: data_b2b } = await useLazyAsyncData('data_b2b', async () => await loadData())
 
 



onMounted(() => {
  useNavbarThemeRequired().value = 'light';
})

onBeforeUnmount(() => {
  useNavbarThemeRequired().value = null;
})




</script>

<style scoped>

.label {
  display: block;
  margin: 0em 0em 0.28571429rem 0em;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.92857143em;
  font-weight: bold;
  text-transform: none;
}

</style>